import { Menu, } from "@mui/material";
import affiliateList from "@/components/Util/nexstarAffilatesData.json";
import { useEffect, useState } from "react";
import { MobileSelectTrigger, SelectTrigger, Input } from "../Util/ContributorSelector.elements";
import { ChevronIcon, FilterIcon } from "@/icons";
import { Spacer } from "@/globalStyles";
import { useIsMobile } from "../hooks";
import { AffiliateFormControl, AffiliateInputMenuItem, AffiliateMenuItem } from "./AffiliateSelector.elements";
import { useContributorsActions } from "@/store";

export const AffiliateSelector = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [affiliates, setAffiliates] = useState(affiliateList);
  const [selectedAffiliateName, setSelectedAffiliateName] = useState('Nexstar Affiliate');
  const [searchTerm, setSearchTerm] = useState('');
  const { selectAffiliate } = useContributorsActions();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    setAffiliates(() => affiliateList.filter(item =>
      item.name.includes(searchTerm)
      || item.location.includes(searchTerm)
    ))
  }, [searchTerm])

  const isMobile = useIsMobile();
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (affiliate) => {
    setAnchorEl(null);
    if (affiliate) {
      selectAffiliate(affiliate.name);
      setSelectedAffiliateName(`${affiliate.name} - ${affiliate.location}`);
    } else if (affiliate === null) {
      selectAffiliate('');
      setSelectedAffiliateName(`Nexstar Affiliate`);
    }
  };

  return (
    <AffiliateFormControl>
      {isMobile ? (
        <MobileSelectTrigger onClick={handleClick}>
          <FilterIcon />
        </MobileSelectTrigger>
      ) : (
        <SelectTrigger onClick={handleClick}>
          <FilterIcon />
          {selectedAffiliateName}
          <Spacer />
          <ChevronIcon />
        </SelectTrigger>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -10, horizontal: 'center' }}
        MenuListProps={{ sx: { background: '#29384D' } }}
        slotProps={{ paper: { sx: { background: 'none' } } }}
      >
        <AffiliateInputMenuItem onKeyDown={(e) => e.stopPropagation()}>
          <Input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </AffiliateInputMenuItem>
        <AffiliateMenuItem  onClick={() => handleClose(null)}>
          All Affiliates
        </AffiliateMenuItem>
        {affiliates.map((affiliate) => (
          <AffiliateMenuItem 
            key={affiliate.name} 
            onClick={() => handleClose(affiliate)}
          >
            {affiliate.name} - {affiliate.location}
          </AffiliateMenuItem>
        ))}
      </Menu>
    </AffiliateFormControl>
  );
};
