import React from "react";
import { AddUserButton, UserIconWraper, UserButtonIcon } from "./UserManagement.elements";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from '@/icons';

const AddNewUserButton = () => {
    const navigate = useNavigate();
  return (
    <>
      <AddUserButton
        variant="outlined"
        onClick={() => navigate("/addnewuser")}
        startIcon={
          <UserIconWraper>
            <UserButtonIcon as={PlusIcon} />
          </UserIconWraper>
        }
      >
        Add User
      </AddUserButton>
    </>
  );
};

export default AddNewUserButton;
