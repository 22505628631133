import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  colors: {
    primary: "#40A31E", // Green
    secondary: "#aeaeb0", // Grey
    blocksBackground: "#3a3a3f", // Dark Grey
    background: "#121C24", //dark blue
    tableRow: "#20243C",
    text: {
      primary: "#ffffff", // White
      secondary: "#b0b0b0", // Light Grey
      lightgrey: "#F7FAFA",
      footer: "#EBBD06", //yellow
    },
    border: "#707070", // Border Grey
    error: "#ff4d4d", // Red
    hover: {
      primary: "#52C71E", // Lighter Green
      secondary: "#d1d1d2", // Lighter Grey
      dropDown: "#4a4a4f",
      navbar: "#4b59f7"
    },
  },
  fonts: {
    body: "'Roboto', sans-serif",
    heading: "'Roboto Slab', serif",
  },
  fontSizes: {
    xsmall: "8px",
    small: "12px",
    xmedium: "14px",
    medium: "16px",
    large: "18px",
    xlarge: "24px",
    h2: "32px",
    h1: "40px"
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  spacing: (factor) => {
    const baseSpacing = 4;
    if (typeof factor === "number") return factor * baseSpacing;
    const spacingList = {
      small: "5px",
      medium: "10px",
      large: "24px",
      xlarge: "36px",
      xxxlarge: "84px",
    }
    if (Object.hasOwn(spacingList, factor)) return spacingList[factor];
    console.error('invalid spacing');
    return baseSpacing;
  },
  breakpoints: {
    mobile: "1080px",
    tablet: "1500px",
  },
  palette: {
    mode: 'dark',
    primary: {
      main: "#5D62B6",
      light: "#8095B7",
      light2: "#8A9EBF",
      dark: "#10121E",
      dark2: "#29384D",
      error: "#F15E6B",
    },
    secondary: {
      main: "#F5C754"
    },
    input: {
      dark1: "#253448",
    },
    success: {
      main: '#69BD44',
    },
    error: {
      main: '#F15E6B'
    },
    grey: {
      500: '#B2B2B2',
    },
    white: {
      main: '#ffffff',
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          padding: "10px 33px",
          lineHeight: "20px",
          textTransform: 'none',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          
        }
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        }
      },
      styleOverrides: {
        paper: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                background: '#10121E',
                border: '1px solid #29384D',
                borderRadius: '8px',
                padding: '8px 0',
                fontSize: '14px',
                lineHeight: '21px',
              }
            }
          ]
        },
        list: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                padding: '0',
              }
            }
          ]
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#121C24',
        }
      }
    }
  },
});
