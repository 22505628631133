import React, { useEffect, useState } from "react";
import {
  GridTableContainer,
  GridTableRow,
  GridTableCell,
  GridTableActionsCell,
} from "./UserManagement.elements"; // Adjust path if needed
import { useContributorsActions, useContributorsState } from "@/store";
import { useAuthState } from "@/store/authSlice"; // Get searchTerm from auth slice
import { Delete } from "@/icons";
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { theme } from "@/theme/theme";

const UsersList = () => {
  const { contributors } = useContributorsState();
  const { searchTerm } = useAuthState(); // Access searchTerm from Redux store
  const { fetchContributors, deleteContributor } = useContributorsActions();

  const [selectedUser, setSelectedUser] = useState(null);  // To track the user to delete
  const [open, setOpen] = useState(false);  // To track if modal is open

  useEffect(() => {
    fetchContributors();
  }, [fetchContributors]);

  const handleDelete = (user) => {
    setSelectedUser(user); // Set the selected user for deletion
    setOpen(true); // Open the confirmation modal
  };

  const handleConfirmDelete = () => {
    if (selectedUser) {
      deleteContributor(selectedUser); // Delete the user
    }
    setOpen(false); // Close the modal
  };

  const handleCancelDelete = () => {
    setSelectedUser(null); // Clear the selected user
    setOpen(false); // Close the modal
  };

  // Filter contributors by both name and email
  const filteredContributors = Array.from(
    contributors
      .filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .reduce((acc, user) => {
        // Use a Map to ensure each user ID is unique
        if (!acc.has(user.id)) {
          acc.set(user.id, user); // Add user to map if ID doesn't exist
        }
        return acc;
      }, new Map()) // Reduce contributors to a Map, with unique user IDs
      .values() // Get the values (unique users) from the Map
  );

  return (
    <>
      <GridTableContainer>
        {/* Rows */}
        {[...filteredContributors.values()].map(
          (
            user // Spread the Map values into an array
          ) => (
            <GridTableRow key={user.id}>
              <GridTableCell>{user.name}</GridTableCell>
              <GridTableCell className="email-column">
                {user.email}
              </GridTableCell>
              <GridTableCell>{user.role}</GridTableCell>
              <GridTableActionsCell>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(user)}
                  style={{ color: theme.palette.primary.error }}
                >
                  <Delete />
                </IconButton>
              </GridTableActionsCell>
            </GridTableRow>
          )
        )}
      </GridTableContainer>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={open}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.input.dark1, 
            borderRadius: "12px",
            padding: "20px",
            textAlign: "center",
            minWidth: "320px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "#fff", paddingBottom: "5px", fontWeight: "12px" }}
        >
          {"Are you sure you want to delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff", fontSize: "18px", fontWeight: "bold" }}
          >
            {selectedUser?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingTop: "16px" }}>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.primary.error,
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "10px 20px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#f24155",
              },
            }}
          >
            Delete
          </Button>
          <Button
            onClick={handleCancelDelete}
            variant="outlined"
            sx={{
              borderColor: "#707070",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "10px 20px",
              textTransform: "none",
              marginLeft: "16px",
              "&:hover": {
                borderColor: "#ffffff",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersList;
