import { IconButton } from "@mui/material"
import { CrossIcon } from "@/icons"

export const CrossButton = ({ onClick, className }) => {
  return (
    <IconButton className={className} onClick={onClick}>
      <CrossIcon />
    </IconButton>
  )
}
