import styled from "@emotion/styled";

// Container for pagination controls
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center; /* Center align pagination controls */
  align-items: center;
  margin: 20px 0; /* Space around pagination controls */
`;

// Style for pagination buttons
export const PaginationButton = styled.button`
  background-color: #3A3A3F; /* Dark background for buttons */
  color: #ffffff; /* White text */
  border: none;
  padding: 10px 20px; /* Padding around text */
  margin: 0 5px; /* Margin between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  
  &:hover {
    background-color: #5A5A5F; /* Slightly lighter on hover */
  }
  
  &:disabled {
    background-color: #6c757d; /* Disabled button color */
    cursor: not-allowed; /* Change cursor when disabled */
  }
`;

// Style for the page size control
export const PageSizeControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Space above page size control */
  margin-bottom: 20px;
`;

export const PageSizeLabel = styled.label`
  margin-right: 10px; /* Space between label and select */
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  color: #ffffff; /* White text */
`;

export const PageAmountText = styled.span`
  color: #ffffff;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
`;

export const PageSizeSelect = styled.select`
  background-color: #3A3A3F; /* Dark background */
  color: #ffffff; /* White text */
  border: 1px solid #5A5A5F; /* Border color */
  padding: 5px 10px; /* Padding around text */
  border-radius: 5px; /* Rounded corners */
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  cursor: pointer; /* Pointer cursor on hover */
  
  &:focus {
    outline: none; /* Remove default focus outline */
    border-color: #1C85C3; /* Border color on focus */
  }
`;

// Container for the entire search form
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

// Wrapper for each input group
export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

// Label for inputs
export const Label = styled.label`
  color: #b0b0b0;
  font-size: 0.9rem;
  margin-bottom: 5px;
`;

// Input fields for search, from, and to
export const InputField = styled.input`
  padding: 10px;
  background-color: #2b2b2b;
  color: #fff;
  border: 1px solid #707070;
  border-radius: 5px;
  font-size: 1rem;
  width: 200px;

  &::placeholder {
    color: #8f8f8f;
  }
`;

// Button styling for OK and RESET buttons
export const Button = styled.button`
  padding: 10px 20px;
  background-color: #40A31E;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }

  &:not(:last-child) {
    margin-right: 10px; /* Adds space between buttons */
  }
`;
