import { UploadingIcon, XCircleIcon } from "@/icons"
import { UploadProgressButton, UploadProgressCardActions, UploadProgressCardContent, UploadProgressCardRow, UploadProgressIconWrapper, UploadProgressInfo, UploadProgressMessage, UploadProgressNumData, UploadProgressSecondButton, UploadProgressTitle } from "./UploadProgress.elements"
import { intervalToDuration } from "date-fns";
import { LinearProgress } from "@mui/material";
import { useState } from "react";
import { usePackagesActions } from "@/store";

const formatRemainingTime = (seconds) =>{
  if (!seconds) return '';
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return [
    duration.hours ? `${duration.hours}h ` : '',
    duration.minutes ? `${duration.minutes}m ` : '',
    duration.seconds ? `${duration.seconds}s` : '',
  ].join('');
}

export const UploadProgressLoadingCard = ({ upload }) => {
  const [isConfirmCancel, setIsConfirmCancel] = useState(false);
  const { cancelUpload } = usePackagesActions();

  return isConfirmCancel ? (
    <UploadProgressCardRow>
      <UploadProgressIconWrapper as={XCircleIcon} status="canceled" />
      <UploadProgressCardContent>
        <UploadProgressTitle ml="48px">
          {upload.uploadMetadata.submissionTitle}
        </UploadProgressTitle>
        <UploadProgressInfo>
          <UploadProgressNumData>
            <span>{formatRemainingTime(upload.remainingTime)}</span>
            <span>{upload.progress ? `${upload.progress}%` : ''}</span>
          </UploadProgressNumData>
          <LinearProgress
            variant={upload.progress ? "determinate" : "indeterminate"}
            value={upload.progress}
            sx={{ mb: '16px' }}
          />
          <UploadProgressMessage>
            Are you sure you want to cancel this uploading?
          </UploadProgressMessage>
        </UploadProgressInfo>
        <UploadProgressCardActions>
          <UploadProgressSecondButton onClick={() => setIsConfirmCancel(false)}>
            Discard
          </UploadProgressSecondButton>
          <UploadProgressButton onClick={() => cancelUpload(upload.uploadId)}>
            Submit
          </UploadProgressButton>
        </UploadProgressCardActions>
      </UploadProgressCardContent>
    </UploadProgressCardRow>
  ) : (
    <UploadProgressCardRow>
      <UploadProgressIconWrapper as={UploadingIcon} status={upload.status} />
      <UploadProgressInfo>
        <UploadProgressTitle>
          {upload.uploadMetadata.submissionTitle}
        </UploadProgressTitle>
        <UploadProgressNumData>
          <span>{formatRemainingTime(upload.remainingTime)}</span>
          <span>{upload.progress ? `${upload.progress}%` : ''}</span>
        </UploadProgressNumData>
        <LinearProgress
          variant={upload.progress ? "determinate" : "indeterminate"}
          value={upload.progress}
        />
      </UploadProgressInfo>
      <UploadProgressButton onClick={() => setIsConfirmCancel(true)}>
        Cancel
      </UploadProgressButton>
    </UploadProgressCardRow>
  )
}
