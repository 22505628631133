import { AbsCrossButton, UploadButton } from "@/components"
import { useState } from "react";
import UploadInputGroup from "../UploadForm/UploadInputGroup";
import { UploadDialog, UploadDialogContent } from "./UploadClips.elements";

export const UploadClips = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <UploadButton handleOpenModal={() => setIsOpen(true)} />
      <UploadDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <UploadDialogContent>
          <AbsCrossButton onClick={() => setIsOpen(false)} />
          <UploadInputGroup handleClose={() => setIsOpen(false)} />
        </UploadDialogContent>
      </UploadDialog>
    </>
  )
}
