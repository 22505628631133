import React, { useLayoutEffect, useState } from "react";
import ReactPlayer from "react-player";
import { FaPlay } from "react-icons/fa";
import {
  CardContainer,
  ThumbnailContainer,
  PlayOverlay,
  CardContent,
  Title,
  Description,
  MetaInfo,
  DateText,
  Badge,
  VideoDurationOverlay,
  BadgeContainer,
} from "./Clip.elements";

const ClipCard = ({ file }) => {
  const [playing, setPlaying] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [video, setVideo] = useState(null);

  useLayoutEffect(() => {
    if (video) {
      video.addEventListener('play', () => {
        video.pause();
      })
    }
  }, [video])

  const handlePlayClick = () => {
    setPlaying(true);
  };

  const resolutionLabels = {
    FOUR_K: "4K",
    HD_PLUS: "HD+",
    HD: "<HD",
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <CardContainer>
      <ThumbnailContainer onClick={handlePlayClick}>
        {!playing ? (
          <>
            <video
              ref={setVideo}
              src={file.videoLink}
              onLoadedData={() => setPreviewLoaded(true)}
              style={{
                display: previewLoaded ? "block" : "none",
                width: "100%",
              }}
              muted
              loop
              autoPlay
              playsInline
            />
            {/* <VideoDurationOverlay>{formatDuration(file.duration)}</VideoDurationOverlay> */}
            <PlayOverlay>
              <FaPlay color="#fff" size={20} />
            </PlayOverlay>

            <BadgeContainer>
              <Badge type={file.resolution}>
                {resolutionLabels[file.resolution]}
              </Badge>
            </BadgeContainer>
          </>
        ) : (
          <ReactPlayer
            url={file.videoLink}
            playing={playing}
            controls
            width="100%"
            height="100%"
          />
        )}
      </ThumbnailContainer>

      <CardContent>
        <Title>{file.headline}</Title>
        <Description>{file.description}</Description>
        {file.processedDate && (
          <MetaInfo>
            <DateText>
              {new Date(file.processedDate * 1000).toLocaleDateString("en-GB", {
                weekday: "short",
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </DateText>
          </MetaInfo>
        )}
      </CardContent>
    </CardContainer>
  );
};

export default ClipCard;
