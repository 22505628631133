import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormGroup, FormError, TimeSelect } from "../Util/Form.elements";
import timeFrameData from "../Util/timeFrameData.json";
import { MenuItem } from "@mui/material";

const TimeFrameSelector = ({ onSelectTimeFrame, defaultValue }) => {
  const { register, formState: { errors } } = useForm(); // Use local useForm hook
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(timeFrameData);
  }, []);

  const handleTimeFrameChange = (e) => {
    const value = e.target.value;

    if (value === "all") {
      // If "all" is selected, don't change the dates
      onSelectTimeFrame({ fromDate: null, toDate: null });
    } else {
      const daysToSubtract = parseInt(value);
      const currentDate = new Date();
      const fromDate = new Date(
        currentDate.setDate(currentDate.getDate() - daysToSubtract)
      ).toISOString();
      const toDate = new Date().toISOString();

      onSelectTimeFrame({ fromDate, toDate });
    }
  };

  return (
    <FormGroup>
      <FormError>{errors.timeframe?.message}</FormError>
      <TimeSelect
        {...register("timeFrame")}
        onChange={handleTimeFrameChange}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TimeSelect>
    </FormGroup>
  );
};

export default TimeFrameSelector;
