import React from "react";
import ClipsGroup from "../../components/Clips/ClipsGroup";
import { ClipsTitle, ClipInputWrapper } from "../../components/Clips/Clip.elements";
import { Container } from "@/globalStyles";

const ClipsPage = (props) => {
  return (
    <Container>
      <ClipInputWrapper>
        <ClipsTitle>Clips</ClipsTitle>
      </ClipInputWrapper>
      <ClipsGroup currentUser={props.currentUser} />
    </Container>
  );
};

export default ClipsPage;
