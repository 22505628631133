import http from "./http-common";
import { getAuthToken } from "./authService";

const getClips = async (props) => {
    const token = await getAuthToken();
    console.log("User token is: ", token);
  
    const params = {
        page: props.page ? props.page : 0,
        size: props.pageSize ? props.pageSize : 10,
      };
  
      if (props.contributors) {
        params.contributors = props.contributors;
      }
      if (props.affiliate) {
        params.affiliate = props.affiliate;
      }
      if (props.category) {
        params.category = props.category;
      }
      if (props.fileName) {
        params.fileName = props.fileName;
      }
      if (props.headline) {
        params.headline = props.headline;
      }
      if (props.fromDate) {
        params.from = props.fromDate;
      }
      if (props.toDate) {
        params.to = props.toDate;
      }
  
    return http.get(`api/v1/clip`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
  };

const getPackageDetails = async (props) => {
    const token = await getAuthToken();
    console.log("User token is: ", token);
  
    const params = {
      uploadMetadata: props.uploadMetadataId,
    };

    return http.get(`api/v1/clip`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
  };

  const ClipService = {
    getClips,
    getPackageDetails,
  };
  
  export default ClipService;
