import { FilterIcon as RawFilterIcon, ChevronIcon as RawChevronIcon, TickIcon } from "@/icons";
import styled from "@emotion/styled";import { IconButton } from "@mui/material";
;

export const FormContainer = styled.div`
  flex-shrink: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-width: 85%;
    padding: 10px;
    margin: auto;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 1008px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const FormLabel = styled.label`
  color: #c7c7cc;
  font-weight: bold;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.small};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px 8px;
  background-color: #263647;
  color: #fff;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.25);
  }

  &::placeholder {
    color: #F7FAFAA6;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;

  &::placeholder {
    color: #8f8f8f;
  }


  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const FilterIcon = styled(RawFilterIcon)`
  width: 18px;
  height: 18px;
  padding: 1px;
`;

export const ChevronIcon = styled(RawChevronIcon)`
  width: 16px;
  height: 16px;
  padding: 2px;
`;

export const SelectTrigger = styled.div`
  padding: 5px;
  background-color: #29384D8C;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
`;

export const MobileSelectTrigger = styled(IconButton)`
  > svg {
    width: 32px;
    height: 32px;
  }
`

export const DropdownMenu = styled.div`
  position: absolute;
  left: 0;
  
  top: calc(100% + 8px);
  background-color: #29384D8C;
  border-radius: 6px;
  max-height: 300px;
  overflow-y: auto;
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: 8px 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    right: 0;
  }
`;

export const DropdownItem = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  cursor: pointer;

  &:hover {
    background-color: #3A506E8C;
  }
`;

export const SelectedIcon = styled(TickIcon)`
  color: #F5C754;
  width: 16px;
  height: 16px;
  padding: 2px;
  margin-left: auto;
`

export const DropdownDivider = styled.hr`
  margin: 3px 8px;
  border: none;
  border-bottom: 1px solid #29384D;
`

export const FormButton = styled.button`
  background-color: #40a31e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
`;
