import React from "react";
import { Box } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { theme } from "../../theme/theme";
import {
  PlaceholderContainer,
  PlaceholderText,
} from "./PieCharts.elements";

const PieChart = (props) => {
  const totalValue = props.data.reduce((acc, item) => acc + item.value, 0);

  //console.log("Resolution data is: ", props.data);
  
  // Check if all values are zero
  const isAllZero = props.data.every((item) => item.value === 0);

  if (isAllZero) {
    // If all values are zero, display a message
    return (
      <PlaceholderContainer>
        <PlaceholderText>No data available</PlaceholderText>
      </PlaceholderContainer>
    );
  }
  
  if (props.data.length > 0) {
    return (
      <Box height="350px" width="350px">
        <ResponsivePie
          data={props.data}
          theme={{
            tooltip: {
              container: {
                color: "#000",
              },
            },
            axis: {
              domain: {
                line: {
                  stroke: theme.colors.secondary,
                },
              },
            },
            legend: {
              text: {
                fill: theme.colors.secondary,
              },
            },
            ticks: {
              line: {
                stroke: theme.colors.secondary,
                strokeWidth: 1,
              },
              text: {
                fill: theme.colors.secondary,
              },
            },
            legends: {
              text: {
                fill: theme.colors.secondary,
              },
            },
          }}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          colors={{ datum: "data.color" }}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor={theme.colors.text.primary}
          arcLinkLabelsDiagonalLength={22}
          arcLinkLabelsThickness={2}
          arcLabelsTextColor="#000"
          arcLinkLabelsColor={{ from: "color" }}
          arcLabel={(d) => `${((d.value / totalValue) * 100).toFixed(0)}%`}
        //   tooltip={({ datum }) => (
        //   <div style={{ color: "#ffffff", background: "#333", padding: "5px", borderRadius: "3px" }}>
        //     <strong>{datum.id}</strong>: {((datum.value / totalValue) * 100).toFixed(2)}%
        //   </div>
        // )} // Format tooltip values as percentages
          enableArcLabels={true}
          arcLabelsRadiusOffset={0.5}
          arcLabelsSkipAngle={7}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "square",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#fff",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    );
  }
};

export default PieChart;
