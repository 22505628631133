import React, {useState} from "react";
import SubmissionUpload from "./UploadSteps/VideoUpload/SubmissionUpload";

const UploadInputGroup = ({ handleClose }) => {
const [currentStep, setStep] = useState('videoUpload');

  //TODO: make the payload include all needed, as no it is one step form
  const renderContent = () => {
    if (currentStep === "videoUpload") {
      return <SubmissionUpload handleNext={handleClose} />;
    }
    return null;
  };

  return renderContent();
};

export default UploadInputGroup;
