import { useMediaQuery, useTheme } from "@mui/material";

export const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.mobile})`);
  return isMobile;
}

export const useIsTablet = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.tablet})`);
  return isTablet;
}
