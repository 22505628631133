import styled from "@emotion/styled";

export const CollapsibleContainerGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 10px;
`;

export const CollapsibleContainer = styled.div`
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 12px 16px;
  border: 0.5px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;

  &:hover {
    background-color: rgba(93, 98, 182, 0.1);
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 14px;
    padding: 12px 20px;
    margin-right: 12px;
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #f7fafa;
`;

export const ArrowIcon = styled.span`
  display: inline-block;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  font-size: 1.5em;
  color: #a1a1a1;
`;

export const Content = styled.div`
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "1000px" : "0")};
  transition: max-height 0.3s ease, margin-top 0.3s ease;
  margin-top: ${({ isOpen }) => (isOpen ? "24px" : "0")};
`;
