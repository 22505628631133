import { GridViewIcon, ListViewIcon } from "@/icons";
import { usePackagesActions, usePackagesState } from "@/store";
import { PackageViewButton } from "./SearchForm.elements";

export const PackageViewSwitcher = () => {
  const { packagesView } = usePackagesState();
  const { setPackagesView } = usePackagesActions();

  const togglePackagesView = () => {
    setPackagesView(packagesView === 'list'? 'grid' : 'list');
  }

  return (
    <PackageViewButton onClick={togglePackagesView}>
      {(packagesView === 'list' && <ListViewIcon />)
      || (packagesView === 'grid' && <GridViewIcon />)}
    </PackageViewButton>
  );
}
