/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { usePackagesActions, usePackagesState } from "@/store";
import PackageSection from "./PackageSection";
import { DashboardContainer, StatsTitle } from "../Stats/Counter/CountersBlock.elements";
import {PackageWrapper} from "./Package.elements";

const DashboardPackageGroupSelection = ({ contributorId }) => {
  const { packages }  = usePackagesState();
  const { fetchPackages } = usePackagesActions();

  const currentPage = 0; // Current page
  const pageSize = 3; // Number of items per page

  useEffect(() => {
    fetchPackages({ contributors: contributorId, currentPage, pageSize });
  }, [contributorId, fetchPackages]);

  // const UploadSpeed = (props) => {
  //   // Extract properties
  //   const { totalSize, uploadTimeProvider, uploadTimeServer } = props;

  //   // Calculate elapsed time in seconds
  //   const elapsedTimeSeconds = (uploadTimeProvider - uploadTimeServer) / 1000;

  //   // Convert total size to MB
  //   const totalSizeMB = totalSize / (1024 * 1024); // Convert bytes to megabytes

  //   // Calculate speed in MB/sec
  //   const speedMBPerSec =
  //     elapsedTimeSeconds > 0 ? totalSizeMB / elapsedTimeSeconds : 0;

  //   return speedMBPerSec.toFixed(2)
  // };

  return (
    <DashboardContainer>
      <PackageWrapper>
      {packages.map((pkg) => (
        <PackageSection
        packageName={pkg.packageName}
        description={pkg.description}
        dateShot={new Date(pkg.createdAt * 1000).toLocaleDateString("en-GB", {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
        status={pkg.status}
        upload={pkg}
      />
      ))}
      </PackageWrapper>
    </DashboardContainer>
  );
};

export default DashboardPackageGroupSelection
