import React from 'react';
import { Spacer } from '@/globalStyles';
import { FacebookIcon, InstagramIcon, LinkedinIcon, XIcon } from '@/icons';
import {
  FooterContainer,
  FooterCopyright,
  FooterTextContainer,
  SocialIcon,
  FooterCopyrightSection,
  FooterLinksSection,
  FooterSocialSection,
  SocialLink,
  FooterLink
} from './Footer.elements';

export const Footer = () => {

  return (
    <FooterContainer>
      <FooterTextContainer>
        <FooterCopyrightSection>
          <FooterCopyright>
            COPYRIGHT 2024 CLIPPN, INC. ALL RIGHTS RESERVED.
          </FooterCopyright>
        </FooterCopyrightSection>
        <FooterLinksSection>
          <FooterLink to="/privacy-policy">PRIVACY POLICY</FooterLink>
          {'|'}
          <FooterLink to="/terms-of-use">TERMS OF USE</FooterLink>
        </FooterLinksSection>
        <Spacer />
        <FooterSocialSection>
          <SocialLink href="https://x.com/clippn" target="_blank" aria-label="X"><SocialIcon as={XIcon} /></SocialLink>
          <SocialLink href="https://www.facebook.com/clippn/" target="_blank" aria-label="Facebook"><SocialIcon as={FacebookIcon} /></SocialLink>
          <SocialLink href="https://www.linkedin.com/company/clippn/" target="_blank" aria-label="LinkedIn"><SocialIcon as={LinkedinIcon} /></SocialLink>
          <SocialLink href="https://www.instagram.com/clippn_inc/" target="_blank" aria-label="Instagram"><SocialIcon as={InstagramIcon} /></SocialLink>
        </FooterSocialSection>
      </FooterTextContainer>
    </FooterContainer>
  );
};
