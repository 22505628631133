import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;          
  padding: 40px;                  
  width: 100%;                    
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;   /* Adjust padding on smaller screens */
  }
`;

export const UserTopElementsBlock = styled.div`
  display: flex;
  justify-content: space-between;   // Places search input on the left and button on the right
  align-items: center;              // Vertically center the input and button
  width: 100%;                      // Full width of the block
  padding: 10px 0;                  // Optional: Adjust padding for spacing

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;         // Stack vertically on mobile
    align-items: stretch;
  }
`;

export const   UserSerchInputWrapper = styled.div`
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;  // Full width on mobile
    padding: 10px;  // Add padding for better spacing
  }
`;

export const UserButtonWrapper = styled.div`
  flex-shrink: 0;
  padding-left: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-left: 0;
    align-self: flex-end;   // Align button to the right on mobile
  }
`;

export const UserListWrapper = styled.div`
  flex-grow: 1;
  display: flex;    
  justify-content: center;   

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;    // Ensure the table fills the width of mobile screens
  }
`;

export const UsersTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h2}; /* Use large font size from the theme */
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary}; /* Use primary text color from the theme */
  margin-bottom: ${({ theme }) => theme.spacing('medium')}; /* Space between title and counters */
  margin-left: 5px;
`;

export const AddUserButton = styled(Button)`
  padding: 12px 40px;               
  font-size: 16px;                   
  color: #fff;                       
  border-color: #fff;                
  transition: all 0.3s ease; 
  &:hover {
    background-color: rgba(93, 98, 182, 0.1); /* Change hover background color */
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    /* Adjust the button on smaller screens */
    font-size: 14px;
    padding: 12px 120px;
    margin-block: 10px;
    margin-right: 12px;
  }
`;

export const UserButtonIcon = styled.svg`
width: 20px;
height: 20px;
`;

export const UserIconWraper = styled.div`
  display: flex;
  justify-content: center;
`;

// The container for the entire grid table
export const GridTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.5fr auto;  // Define the column sizes
  gap: 16px;  // Gap between columns
  padding: 16px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr 0.5fr auto;  // Remove the email column on small screens
  }
`;

// Header row (fixed position)
export const GridTableHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 12px 0;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  white-space: nowrap;  // Prevents text wrapping

  &.email-column {
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      display: none;  // Hide email column on mobile
    }
  }
`;

// Table row
export const GridTableRow = styled.div`
  display: contents;  // Use display: contents to inherit grid behavior for individual cells
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  &:last-child {
    border-bottom: none;  // Remove border on the last row
  }
`;

// Table cell (regular)
export const GridTableCell = styled.div`
  padding: 12px 0;
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;  // Prevents wrapping

  &.email-column {
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      display: none;  // Hide email column on mobile
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    white-space: wrap;  // Allows wrapping on small screens
  }
`;

// Actions cell (for buttons, etc.)
export const GridTableActionsCell = styled.div`
  padding: 12px 0;
  text-align: center;  // Center the actions (buttons)
  min-width: 60px;  // Ensure a minimum width for the action buttons
`;

