import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserTrigger,
  UserIcon,
  UserInfo,
  UserName,
  UserRole,
  UserDropdownIcon,
  UserMenuIcon,
  UserMenuInfo,
  RoleMenuInfo,
} from "./UserProfile.elements";
import { Menu, MenuItem } from "@mui/material";
import { ExitIcon, LockIcon } from "@/icons";
import { MenuOutlinedDelimiter } from "@/globalStyles";
import { useIsTablet } from "../hooks";
import { useAuthState, useAuthActions } from "@/store";

export const UserProfile = () => {
  const { currentUser } = useAuthState();
  const { signOut } = useAuthActions();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = !!anchorEl;
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const isTablet = useIsTablet();

  const handleGoToChangePassword = () => {
    navigate("/changepassword");
    handleClose();
  };

  const userInfo = (
    <UserInfo>
      <UserName>{currentUser.name}</UserName>
      <UserRole>{currentUser.email}</UserRole>
    </UserInfo>
  );

  return (
    <>
      <UserTrigger onClick={handleOpen} variant="text">
        <UserIcon />
        {!isTablet && (
          <>
            {userInfo}
            <UserDropdownIcon isOpen={isOpen} />
          </>
        )}
      </UserTrigger>
      <Menu
        variant="outlined"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        {isTablet && (
          <>
            <UserMenuInfo>{userInfo}</UserMenuInfo>
            <MenuOutlinedDelimiter />
          </>
        )}
        <RoleMenuInfo>
          <UserRole>{currentUser.role}</UserRole>
        </RoleMenuInfo>
        <MenuOutlinedDelimiter />
        <MenuItem onClick={handleGoToChangePassword}>
          <UserMenuIcon as={LockIcon} />
          Change password
        </MenuItem>
        <MenuOutlinedDelimiter />
        <MenuItem onClick={signOut}>
          <UserMenuIcon as={ExitIcon} />
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};
