import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from './loadingSlice';
import { showAlert, hideAlert } from './alertSlice';
import UploadService from '@/Service/UploadService';
import ClipService from '@/Service/ClipService';

const isValidUUID = (uuid) => {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
};

export const fetchPackages = createAsyncThunk(
  'packages/fetchPackages',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const response = await UploadService.getFiles(params);
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching the uploads."))
      console.error("Error fetching data:", error);
      return rejectWithValue(error);
    }
  }
)

export const uploadPackage = createAsyncThunk(
  'packages/uploadPackage',
  async (rawPayload, { dispatch, rejectWithValue }) => {
    const uploadId = crypto.randomUUID(); // Generate a unique uploadId

    // Attach uploadId and add start time to metadata
    const payload = {
      ...rawPayload,
      uploadMetadata: {
        ...rawPayload.uploadMetadata,
        uploadId,
        startUploadTime: new Date().toISOString(),
      },
    };

    try {
      dispatch(createUploadingPackage({ ...payload, uploadId }));
      dispatch(setIsProgressDropdownOpen(true));

      // Pass structured payload to UploadService.upload
      const response = await UploadService.upload(payload, (progress, remainingTime) => {
        dispatch(updateUploadingPackage({ uploadId, progress, remainingTime }));
      });

      dispatch(updateUploadingPackage({ uploadId, status: 'succeeded' }));
      return response.data;
    } catch (error) {
      dispatch(updateUploadingPackage({ uploadId, status: 'failed', error: error.message }));
      return rejectWithValue(error);
    }
  }
)

export const retryUploadPackage = createAsyncThunk(
  'packages/retryUploadPackage',
  async (uploadId, { dispatch, rejectWithValue, getState }) => {
    const uploadingPackages = getState().packages.uploadingPackages;
    console.log('uploadingPackages', uploadingPackages);
    const previousPayload = uploadingPackages.find((p) => p.uploadId === uploadId);
    console.log('previousPayload', previousPayload);
    if (!previousPayload) {
      console.error("No previous payload found for the given uploadId");
      return;
    }
    const { files, uploadMetadata } = previousPayload

    const payload = { files, uploadMetadata };
    console.log('payload', payload);
    try {
      dispatch(setIsProgressDropdownOpen(true));
      dispatch(updateUploadingPackage({ uploadId, status: 'loading' }));
      const response = await UploadService.upload(payload, (progress, remainingTime) => {
        dispatch(updateUploadingPackage({ uploadId, progress, remainingTime }));
      });
      dispatch(updateUploadingPackage({ uploadId, status: 'succeeded' }));
      return response.data;
    } catch (error) {
      dispatch(updateUploadingPackage({ uploadId, status: 'failed', error: error.message }));
      return rejectWithValue(error);
    }
  }
)

export const cancelUpload = createAsyncThunk(
  'packages/cancelUpload',
  async (uploadId, { dispatch, rejectWithValue, getState }) => {
    if (!isValidUUID(uploadId)) {
      console.error("Invalid UUID format");
      return;
    } else {
      dispatch(updateUploadingPackage({ uploadId, status: 'canceled' }));
      await UploadService.cancel(uploadId);
    }
  }
);

export const fetchPackageDetails = createAsyncThunk(
  'packages/fetchPackageDetails',
  async (uploadMetadataId, { dispatch, rejectWithValue }) => {
    try {
      dispatch(showLoading());
      dispatch(hideAlert());
      const response = await ClipService.getPackageDetails({ uploadMetadataId });
      dispatch(hideLoading());
      return response.data;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "There was an error while fetching the upload details."))
      console.error("Error fetching data:", error);
      return rejectWithValue(error);
    }
  }
)

const packagesSlice = createSlice({
  name: 'packages',
  initialState: {
    packages: [],
    packagesView: 'list', // list | grid
    totalPages: 0,
    fetch: {
      status: 'idle', // idle | loading | succeeded | failed
      error: null,
    },
    isProgressDropdownOpen: false,
    uploadingPackages: [],
    isRetryUploading: false,
    packageDetails: undefined,
    fetchDetails: {
      status: 'idle', // idle | loading | succeeded | failed
      error: null,
    },
    // uploadingPackages: [
    //   { uploadId: '1', status: 'loading', files: {}, uploadMetadata: { packageName: 'Test upload 1' } },
    //   { uploadId: '2', status: 'loading', progress: 10, remainingTime: 4215, files: {}, uploadMetadata: { packageName: 'Test upload 2' } },
    //   { uploadId: '3', status: 'loading', progress: 90, remainingTime: 15, files: {}, uploadMetadata: { packageName: 'Test upload 3' } },
    //   { uploadId: '4', status: 'succeeded', progress: 100, remainingTime: 0, files: {}, uploadMetadata: { packageName: 'Test upload 4' } },
    //   { uploadId: '5', status: 'failed', progress: 99, remainingTime: 0, files: {}, uploadMetadata: { packageName: 'Test upload 5' } },
    //   { uploadId: '6', status: 'canceled', progress: 99, remainingTime: 0, files: {}, uploadMetadata: { packageName: 'Test upload 6' } },
    // ],
  },
  reducers: {
    setIsProgressDropdownOpen: (state, action) => {
      state.isProgressDropdownOpen = action.payload;
    },
    createUploadingPackage: (state, action) => {
      const payload = { ...action.payload, status: 'loading' };
      state.uploadingPackages = [payload, ...state.uploadingPackages]
    },
    updateUploadingPackage: (state, action) => {
      const { uploadId, ...rest } = action.payload;
      if (rest.status === 'failed') {
        const upload = state.uploadingPackages.find((p) => p.uploadId === uploadId);
        if (upload.status === 'canceled') {
          return; // No changes
        }
      }
      state.uploadingPackages = state.uploadingPackages.map((p) => p.uploadId === uploadId ? { ...p, ...rest } : p)
    },
    deleteUploadingPackage: (state, action) => {
      state.uploadingPackages = state.uploadingPackages.filter((p) => p.uploadId !== action.payload)
    },
    setPackagesView: (state, action) => {
      state.packagesView = action.payload;
    },
  },
  extraReducers: (builder) =>{
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.fetch.status = 'loading';
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.fetch.status = 'succeeded';
        state.packages = action.payload.content;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchPackages.rejected, (state, action) => {
        state.fetch.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPackageDetails.pending, (state) => {
        state.fetchDetails.status = 'loading';
      })
      .addCase(fetchPackageDetails.fulfilled, (state, action) => {
        state.fetchDetails.status = 'succeeded';
        state.packageDetails = action.payload.content;
      })
      .addCase(fetchPackageDetails.rejected, (state, action) => {
        state.fetchDetails.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(retryUploadPackage.pending, (state) => {
        state.isRetryUploading = true;
      })
      .addCase(retryUploadPackage.fulfilled, (state, action) => {
        state.isRetryUploading = false;
      })
      .addCase(retryUploadPackage.rejected, (state, action) => {
        state.isRetryUploading = false;
      })
  }
});

export const {
  setIsProgressDropdownOpen,
  createUploadingPackage,
  updateUploadingPackage,
  deleteUploadingPackage,
  setPackagesView,
} = packagesSlice.actions;

export const usePackagesState = () => useSelector(state => state.packages);
export const usePackagesActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => ({
    setIsProgressDropdownOpen: (isOpen) => dispatch(setIsProgressDropdownOpen(isOpen)),
    uploadPackage: (metadata) => dispatch(uploadPackage(metadata)),
    cancelUpload: (uploadId) => dispatch(cancelUpload(uploadId)),
    retryUploadPackage: (uploadId) => dispatch(retryUploadPackage(uploadId)),
    deleteUploadingPackage: (uploadId) => dispatch(deleteUploadingPackage(uploadId)),
    fetchPackages: (params) => dispatch(fetchPackages(params)),
    setPackagesView: (params) => dispatch(setPackagesView(params)),
    fetchPackageDetails: (uploadMetadataId) => dispatch(fetchPackageDetails(uploadMetadataId)),
  }), [dispatch]);
}

export default packagesSlice.reducer;
