import React from "react";
import PieChart from "./PieChart";
import { ChartsBlock } from "./Counter/CountersBlock.elements";

const PieCharts = (props) => {

  console.log("PieCharts data:", props.stats)

  const resolutionData = [
    {
      id: "resolution4K",
      label: "4K",
      value: props.stats.resolution4K || 0,
      color: "#3E79BD", // blue color
    },
    {
      id: "resolutionHDPlus",
      label: "HD+",
      value: props.stats.resolutionHDPlus || 0,
      color: "#F15E6B", // red color
    },
    {
      id: "resolutionHD",
      label: "<HD",
      value: props.stats.resolutionHD || 0,
      color: "#8095B7", // grey color
    },
  ];

  const typeData = [
    {
      id: "commercialClips",
      label: "commercial",
      value: props.stats.commercialClips || 0,
      color: "#5D62B6", // Dark purple color
    },
    {
      id: "editorialClips",
      label: "editorial",
      value: props.stats.editorialClips || 0,
      color: "#BC96DF", // Light violet color
    }
  ];

  return (
    <ChartsBlock>
    <PieChart data={resolutionData}/>
    <PieChart data={typeData}/>
    </ChartsBlock>
  )
}

export default PieCharts
