import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@/icons";
import { theme } from "@/theme/theme";
import { useDispatch } from "react-redux";  // Import useDispatch
import { setSearchTerm } from "@/store/authSlice";  // Import setSearchTerm action

const UserSearchTextField = () => {
  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    const value = event.target.value;
    dispatch(setSearchTerm(value));  // Dispatch the search term to Redux store
  };

  return (
    <TextField
      id="filled-basic"
      label="Enter Name or Email"
      variant="filled"  // Filled variant of the TextField
      onChange={handleSearchChange}  // Handle input change
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        disableUnderline: true,  // Disable the default bottom underline
        
      }}
      sx={{
        "@media (max-width: 1008px)": {
          width: "100%",
        },
        '& .MuiFilledInput-root': {
          //backgroundColor: '#3a3a3f',  // Custom background color
          borderRadius: '8px',         // Rounded corners
        },
        '& .MuiFilledInput-underline:before': {
          borderBottom: 'none',  // Remove the bottom border before hover
        },
        '& .MuiFilledInput-underline:after': {
          borderBottom: 'none',  // Remove the bottom border after focus
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.primary.light2,  // Label color
        },
      }}
    />
  );
};

export default UserSearchTextField;