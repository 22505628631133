import styled from "@emotion/styled";;

export const MetadataTitleBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5px;
`;

export const MetadataTitle = styled.div`
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
`;

export const MetadataSubTitle = styled.div`
  text-align: center;
  margin-bottom: 14px;
  color: #D57100;
  font-style: italic;
  font-size: 0.8rem;
`;

export const FormContainer = styled.div`
  max-width: 1000px;
  margin: auto;
`;

export const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 1008px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    > * {
      width: 49%;
    }
  }
`;

export const FormGroup = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const FormLabel = styled.label`
  color: #fff; /* White color for the label */
  margin-bottom: 0px; /* Space between label and input */
`;

export const FormError = styled.p`
  color: #ff4d4d; /* White color for the error message */
  margin-bottom: -5px; /* Remove default margin */
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #f0f0f0;
`;

export const Select = styled.select`
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #f0f0f0;
  border: 1px solid #ccc; /* Optional: border color to match the input */
  font-size: ${({ theme }) => theme.fontSizes.medium};
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  appearance: none; /* Remove default dropdown arrow */
  background-size: 12px; /* Adjust size of dropdown arrow */
  appearance: auto;

  &:focus {
    outline: none;
    border-color: #007bff; /* Optional: change border color on focus */
    box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.25); /* Optional: add shadow on focus */
  }
`;

export const Option = styled.option`
  /* Option styling is not needed for most cases, as options inherit from the select element */
`;

export const CustomDatePickerInput = styled.input`
  padding: 5px; /* Add padding to the DatePicker input */
  border-radius: 3px; /* Optional: Rounded corners */
  width: 100%;
  background-color: #f0f0f0;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ddd; /* Optional: Add a border for better visibility */
  border-radius: 3px; /* Optional: Rounded corners */
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const FormButton = styled.button`
  background-color: ${(props) =>
    props.buttonState === "previous" ? "#ccc" : "#007bff"};
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
`;
