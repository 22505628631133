import styled from "@emotion/styled";
import { FormControl, MenuItem } from "@mui/material";

export const AffiliateFormControl = styled(FormControl)`
  min-width: 48px;
  font-size: 14px;

  @media screen and (min-width: 1008px) {
    min-width: 170px;
  }
`

export const AffiliateMenuItem = styled(MenuItem)`
  font-size: 14px;
  padding: 8px 16px;
  min-height: auto;
`
export const AffiliateInputMenuItem = styled(AffiliateMenuItem)`
  padding: 0;
`
