import styled from "@emotion/styled";
import { Dialog, DialogContent } from "@mui/material";

export const UploadDialog = styled(Dialog)`
  > div > .MuiPaper-root {
    margin: 32px;
    width: 100%;
    max-width: 600px;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      margin: 0;
      width: 100%;
      max-width: 100%;
      top: 0;
      position: fixed;
      max-height: 100%;
    }
  }
`;

export const UploadDialogContent = styled(DialogContent)`
  background-color: ${({ theme }) => theme.palette.primary.dark2};
  position: relative;
  padding: 20px 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 16px; /* Smaller padding for mobile */
    height: 100%; /* Ensure the content takes the full height */
  }
`;
