import styled from "@emotion/styled";
import { Link } from "react-router-dom";

// Container for the entire footer
export const FooterContainer = styled.footer`
  padding: 24px 40px;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  gap: 8px;
`;

export const FooterCopyrightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  order: 1;
  text-align: center;
`;

export const FooterCopyright = styled.div`
  font-weight: 500;
  line-height: 40px;
`;

export const FooterDotDivider = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.common.white};
  margin: 0 64px;
`;

export const FooterLinksSection = styled.div`
  display: flex;
  gap: 16px;
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const FooterSocialSection = styled.div`
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const SocialLink = styled.a`
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: color 0.3s ease;
`;
// Social media icon wrapper
export const SocialIcon = styled.svg`
  width: 24px;
  height: 24px;
  vertical-align: top;
`;
