import React from "react";
import PackageGroupSelection from "../../components/PackagesSection/PackageGroupSection";
import {
  ClipsTitle,
  ClipInputWrapper,
} from "../../components/Clips/Clip.elements";
import { Container } from "@/globalStyles";

const UploadsPage = (props) => {
  return (
    <Container>
      <ClipInputWrapper>
        <ClipsTitle>Uploads</ClipsTitle>
        <PackageGroupSelection currentUser={props.currentUser} />
      </ClipInputWrapper>
    </Container>
  );
};

export default UploadsPage;
