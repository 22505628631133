import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { NavLink as RouterNavLink } from "react-router-dom";
import { CrossButton } from "../CrossButton/CrossButton";

export const NavPlaceholder = styled.div`
  flex-basis: 0;
  width: 0;
  flex-shrink: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-basis: ${({ isCollapsed }) => isCollapsed ? '80px' : '363px'};
    width: ${({ isCollapsed }) => isCollapsed ? '80px' : '363px'};
    transition: width 0.2s, flex-basis 0.2s;
  }
`;

export const NavContainer = styled.div`
  width: 100vw;
  height: 100dvh;
  translate: ${({ isMobileMenuOpen }) => isMobileMenuOpen ? '0 0 ' : '-100% 0'};
  transition: translate 0.2s;
  background: ${({ theme }) => theme.palette.primary.dark};
  z-index: 10;
  gap: 3px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: inherit;
    height: auto;
    translate: 0;
  }
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
`

export const NavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #29384D;
  height: 90px;
  align-self: flex-end;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 363px;
  }
`;

export const MobileCloseButton = styled(CrossButton)`
  > svg {
    width: 18px;
    height: 18px;
  }
`;

export const CollapseButton = styled(IconButton)`
  rotate: ${({ isCollapsed }) => isCollapsed ? '180deg' : '0deg'};
  transition: rotate 0.2s;
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 36px 16px;
`;

export const NavIcon = styled.span`
  flex-shrink: 0;
`;

export const NavLink = styled(RouterNavLink)`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 14px 12px;
  border-radius: 6px;
  color: #F7FAFAA6;
  overflow: hidden;

  &:hover {
    background-color: #1B2432;
    color: white;

    ${NavIcon} {
      color: #F5C754;
    }
  }

  &.active {
    background-color: #29384D;
    color: white;

    ${NavIcon} {
      color: #F5C754;
    }
  }
`;

export const NavDivider = styled.hr`
  border: none;
  border-top: 1px solid #29384D;
  margin: 8px 0;
`;
