import styled from "@emotion/styled";

export const StatusWrapper = styled.div`
display: flex;
align-items: center;
gap: 10px;
`;

export const StatusText = styled.span`
color: #fff;
font-size: 16px;
`;

export const SvgWrapper = styled.svg`
width: 40px;
height: 40px;
`;