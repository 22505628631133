import { Button } from "@mui/material";

import styled from "@emotion/styled";
import { CrossButton } from "../CrossButton/CrossButton";

export const UploadProgressPanel = styled.div`
 background: rgba(41, 56, 77, 0.8);
  padding: 16px 33px;
  width: auto;
  max-width: 1000px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 8px;

  @media screen and (max-width: 1008px) {
    max-width: 90%;
    padding: 10px 12px;
    flex-direction: column;
    background: ${({ theme }) => theme.palette.primary.dark2};
  }
`;

export const UploadProgressCard = styled.div`
  background: #29384d8c;
  padding: 8px 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: relative;
`;

export const UploadProgressCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const UploadProgressCardRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UploadProgressIconWrapper = styled.span`
  width: 36px;
  height: 36px;
  color: ${({ status, theme }) =>
    (status === "succeeded" && theme.palette.success.main) ||
    (status === "failed" && theme.palette.error.main) ||
    (status === "canceled" && theme.palette.grey[500]) ||
    theme.palette.primary.main};
  margin-right: 16px;

  @media screen and (max-width: 1008px) {
    margin-bottom: 8px;
  }
`;

export const UploadProgressInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;

  @media screen and (max-width: 1008px) {
    width: 100%;
  }
`;

export const UploadProgressTitle = styled.div`
  font-size: 14px;
  color: #f7fafaa6;
`;
export const UploadProgressNumData = styled.div`
  font-size: 12px;
  color: #f7fafaa6;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 4px;
`;
export const UploadProgressMessage = styled.div`
  font-size: 14px;
  color: #f7fafa;
`;

export const UploadProgressButton = styled(Button)`
  padding: 2px 30px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${({ theme }) =>
    theme.palette.primary.error}; /* red background */
  color: #fff; /* white text */
  text-transform: none;

  &:hover {
    background-color: #f24155; /* hover color */
  }
`;

export const UploadProgressSecondButton = styled(Button)`
  padding: 2px 30px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #707070; /* outlined border */
  color: #fff; /* white text */
  text-transform: none;

  &:hover {
    border-color: #ffffff; /* hover border color */
  }
`;

export const UploadProgressCardActions = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
`;

export const UploadAbsCrossButton = styled(CrossButton)`
  position: relative;
`;
