import styled from "@emotion/styled";;

export const DashboardContainer = styled.div`
z-index: 1;
width: 100%;
max-width: 1440px;
margin-top: 30px;
margin-right: auto;
margin-left: auto;

@media screen and (max-width: 1008px) {
    padding-right: 10px;
    padding-left: 10px;
}
`;

export const CounterStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing('large')};
  //gap: 10px; /* Adjust gap between title and the counters */
`;

export const StatsTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h2}; /* Use large font size from the theme */
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary}; /* Use primary text color from the theme */
  margin-bottom: ${({ theme }) => theme.spacing('medium')}; /* Space between title and counters */
  text-align: center;
`;

export const CounterBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing('large')};/* Space between each counter */
`;

export const ChartsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: "5px";/* Space between each counter */
`;
