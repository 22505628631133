import React from "react";
import UsersList from "../../components/UserManagement/UsersList";
import AddNewUserButton from "@/components/UserManagement/AddNewUserButton";
import {
  UserContainer,
  UserButtonWrapper,
  UserTopElementsBlock,
  UserSerchInputWrapper,
  UserListWrapper,
  UsersTitle,
} from "../../components/UserManagement/UserManagement.elements";
import UserSearchTextField from "@/components/UserManagement/UserSearchTextField";

const AddUserPage = () => {
  return (
    <UserContainer>
      <UserSerchInputWrapper>
        <UsersTitle>Contributors</UsersTitle>
      </UserSerchInputWrapper>

      <UserTopElementsBlock>
        <UserSerchInputWrapper>
          <UserSearchTextField />
        </UserSerchInputWrapper>

        <UserButtonWrapper>
          <AddNewUserButton />
        </UserButtonWrapper>
      </UserTopElementsBlock>
      
      <UserListWrapper>
        <UsersList />
      </UserListWrapper>
    </UserContainer>
  );
};

export default AddUserPage;
