import { FileIcon, UploadCloudIcon } from "@/icons";
import styled from "@emotion/styled";
import { CrossButton } from "../CrossButton/CrossButton";
import { Button } from "@mui/material";

export const FormButton = styled.button`
  border-radius: 3px;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  font-size: ${({ fontBig }) => (fontBig ? "24px" : "15px")};
  font-weight: bold;
  color: #fff;
  outline: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${({ buttonState }) => {
    switch (buttonState) {
      case "inactive":
        return "dark-grey";
      case "next":
        return "#40A31E";
      case "previous":
        return "#AEAEB0";
        case "auth":
          return "#337AB7";
      default:
        return "lightgrey";
    }
  }};

  &:hover {
    transition: ${(props) => props.disabled ? 'inherit' : 'all 0.3s ease-out'};
    background: ${(props) => props.disabled ? 'dark-grey' : 'rgba(255, 255, 255, 0.5)'};
  }

  @media screen and (max-width: 1008px) {
    width: 60%;
  }
`;

export const UploadButton = styled(Button)`
  
`;

export const UploadTitleBlock = styled.div`
display: flex;
align-items: flex-start;
justify-content: center;
flex-direction: row;
padding-bottom: 20px;
`;

export const DropTitleBlock = styled.div`
display: flex;
align-items: flex-start;
flex-direction: row;
padding-bottom: 14px;
`;

export const DropNormalBlock = styled.div`
display: flex;
align-items: flex-start;
flex-direction: row;
padding-bottom: 14px;
`;

export const DropTitleText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-bottom: 24px;
`;

export const DropNormalText = styled.div`
  padding-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const DropHighlightedText = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const UploadTitleText = styled.div`
  //padding-left: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.2rem;
  text-align: center;
  color: ${({ textColor }) => {
    switch (textColor) {
      case "grey":
        return "#AEAEB0";
      case "green":
        return "#40A31E";
      default:
        return "#fff";
    }
  }};
`;

export const UploadIconBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadIcon = styled(UploadCloudIcon)`
  color: "AEAEB0";
`;

export const FileItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 3px; /* Rounded corners */
  padding-inline: 10px;
  padding-block: 5px;
`;

export const DeleteIcon = styled(CrossButton)`
`;

export const DropBlock = styled.div`
  align-items: center;
  gap: 10px; /* Space between items */
`;

export const FileList = styled.ul`
  padding: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: #202C3DBF;
  color: #fff;
  border-radius: 8px;
`;

export const FileDelimiter = styled.hr`
  border-color: #FFFFFF0C;
  border-width: 1px 0 0;
  margin: 0 16px;
`;

export const FileItemIcon = styled(FileIcon)``;


export const FilePath = styled.span`
  flex: 1; /* Allow the file path to take up available space */
  margin-right: 10px; /* Space between file path and file size */
  overflow-wrap: break-word; /* Break long words to prevent overflow */
  word-break: break-word; /* Break words if they exceed the container width */
  max-width: calc(100% - 120px); /* Adjust this based on the width needed for file size and delete button */
`;

export const FileSize = styled.span`
  color: #B2B2B2;
`;

export const UploaderContainer = styled.div`
  padding: 10px;
  //margin-top: 10px;
  background-color: #3a3a3f;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProgressProcessBlock = styled.div`
display: flex;
align-items: center;
flex-direction: row;
justify-content: center;
width: 100%;
//padding-bottom: 14px;
gap: 15px;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  height: 20px;
  position: relative;
`;

export const ProgressBarFill = styled.div`
  width: ${props => props.progress}%;
  background-color: #40A31E;
  height: 100%;
  border-radius: 5px;
  transition: width 0.4s ease;
`;

export const ProgressText = styled.div`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  color: #fff;
  text-align: center;
  line-height: 20px;
`;

export const ProgressStatusText = styled.div`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.fontSizes.xmedium};
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  color: ${(props) => {
    switch (props.status) {
      case 'Successfully uploaded':
        return '#40A31E'; // Success color
      case 'Failed to upload':
        return '#ff4d4d'; // Error color
      case 'Canceled':
        return '#D9D9D9'; // Cancel color
      default:
        return '#fff'; // Default color if status is not recognized
    }
  }};
  transition: color 0.3s ease-out; // Smooth transition for color changes
`;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  top: 70px; /* Adjust based on navbar height */
  right: 20px; /* Maintain 20px from the right edge */
  width: 30%;
  max-width: 600px; /* Optional: Set a maximum width for larger screens */
  padding: 20px; /* Add padding for better spacing */
  z-index: 999; /* Ensure it is above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */

  display: flex;
  justify-content: center; /* Align content to the right */

  /* Ensure the alignment doesn't jump to the right */
  transform: none;

  @media (max-width: 1000px) {
    width: 90%; /* Adjust width for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    right: 20px; /* Maintain 20px from the right edge for smaller screens */
    left: auto; /* Ensure left is not affecting positioning */
    transform: none; /* Reset transform to prevent horizontal centering */
    margin-left: auto; /* Center the element horizontally */
    margin-right: auto; /* Center the element horizontally */
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: row-reverse;
`;

// Container for the entire stepper
export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

// Individual step style
export const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Circle for step number with conditional styling for active/inactive states
export const StepCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#8F7EE7' : '#CCCCCC')};  // Change background color based on active state
  color: ${(props) => (props.active ? '#FFFFFF' : '#000000')};              // Change text color based on active state
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.3s ease;  // Smooth transition when changing color
`;

// Horizontal line between steps, centered vertically
export const StepLine = styled.div`
  width: 50px;
  height: 2px;
  background-color: ${(props) => (props.active ? '#8F7EE7' : '#CCCCCC')};  // Line color also changes based on step
  margin: 0 10px;
  align-self: center;  // Center the line vertically
`;
