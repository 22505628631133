import styled from "@emotion/styled";
import {Box, TextField, Button} from "@mui/material"

export const CardContainer = styled.div`
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
`;

export const VideoThumbnail = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PlayOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing('medium')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
`;

export const VideoDurationOverlay = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 0.85rem;
  font-weight: bold;
`;

export const CardContent = styled.div`
  padding: 15px;
  background-color: transparent; /* No background color */
`;

export const Title = styled.h3`
  font-size: 1.1rem;
  margin: 5px 0;
  color: #fff;
`;

export const Description = styled.p`
  font-size: 0.9rem;
  margin: 5px 0;
  color: #aaa;
`;

export const MetaInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing('medium')};
`;

export const DateText = styled.span`
  font-size: 0.8rem;
  color: #bbb;
`;

export const Badge = styled.span`
  background-color: ${(props) => {
  switch (props.type) {
    case 'FOUR_K':
      return '#4caf50'; // Green for FOUR_K
    case 'HD_PLUS':
      return '#FF9800'; // Orange for HD_PLUS
    case 'HD':
      return '#f44336'; // Red for HD
    default:
      return '#000'; // Default color if none match
  }
}};
  color: #fff;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: bold;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing('large')};
  padding-block: ${({ theme }) => theme.spacing('large')};
  padding-inline: ${({ theme }) => theme.spacing('medium')};
  flex-direction: row;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
    padding-block: 10px;
  }
`;

export const ClipInputWrapper = styled.div`
  flex-grow: 1;
  align-items: center;
  gap: 16px;
  padding: ${({ theme }) => theme.spacing('large')};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;           // Stack elements vertically on mobile devices
    align-items: stretch;             // Ensure the items stretch on mobile
    gap: 10px;  
    width: 100%;
    padding: 10px; 
  }
`;

export const ClipTopElementsBlock = styled.div`
  display: flex;
  justify-content: space-between;   // Places search input on the left and button on the right
  align-items: center;              // Vertically center the input and button
  width: 100%;                      // Full width of the block
  padding: 10px 0;                  // Optional: Adjust padding for spacing

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;         // Stack vertically on mobile
    align-items: stretch;
  }
`;

export const ClipsTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.h2}; /* Use large font size from the theme */
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary}; /* Use primary text color from the theme */
  margin-bottom: ${({ theme }) => theme.spacing('medium')}; /* Space between title and counters */
  margin-left: 5px;
`;

export const DateRangePickerWrapper = styled.div`
  .rdrCalendarWrapper {
    background-color: #181b24; /* Dark background */
    color: #c7c7c7; /* Lighter text color */
    border-radius: 10px;
    padding: 20px;
  }

  .rdrMonthAndYearPickers select {
    background-color: #181b24; /* Dropdown background */
    color: #fff;
    border: none;
  }

  .rdrDateDisplay {
    background-color: #22252e; /* Input background */
    border: 1px solid #30303d;
  }

  .rdrDateDisplay input {
    background-color: #22252e;
    color: #fff;
    border: 1px solid #30303d;
    border-radius: 4px;
  }

  .rdrDefinedRangesWrapper {
    background-color: #1c1e26; /* Left panel background */
    border-right: 1px solid #30303d;
  }

  .rdrDefinedRangesItem {
    color: #fff;
  }

  .rdrDayDisabled {
    color: #555;
  }

  .rdrDayNumber span {
    color: #c7c7c7; /* Default day color */
  }

  .rdrDayToday .rdrDayNumber span {
    border: 1px solid #4d90fe; /* Border for today's date */
  }

  .rdrDayInPreview {
    background-color: rgba(77, 144, 254, 0.3); /* Preview range background */
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    background-color: #4d90fe; /* Selected preview start/end date */
    color: white;
  }

  .rdrDayHovered .rdrDayNumber span {
    background-color: rgba(77, 144, 254, 0.7); /* Hovered day background */
    color: white;
  }

  .rdrDayActive .rdrDayNumber span {
    background-color: #6d54e0; /* Active selected date */
    color: white;
    border-radius: 4px;
  }

  .rdrNextPrevButton {
    background-color: transparent; /* Remove default button color */
    color: #fff; /* Make arrows white */
  }

  .rdrStaticRanges li,
  .rdrInputRanges li {
    color: #fff; /* Text color for custom ranges */
  }

  .rdrStaticRangeSelected {
    background-color: #6d54e0; /* Selected range background */
  }

  .rdrDefinedRangesItem:hover {
    background-color: rgba(109, 84, 224, 0.3); /* Hover background for custom ranges */
  }

  .rdrDateRangePickerWrapper .rdrDateDisplayWrapper .rdrDateDisplayItem input {
    color: #fff;
    background-color: #22252e;
    border: 1px solid #30303d;
  }

  /* Buttons */
  .rdrDateRangePickerWrapper .rdrButton {
    background-color: #6d54e0; /* Apply button background */
    color: white;
  }

  .rdrDateRangePickerWrapper .rdrButton:hover {
    background-color: #5c47c0;
  }

  .rdrCancelButton {
    background-color: transparent;
    color: #c7c7c7;
  }

  .rdrCancelButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const CustomContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  color: '#e0e0e0',
  padding: '20px',
  borderRadius: '10px',
}));

export const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    color: '#e0e0e0',
    backgroundColor: '#2e2e2e',
  },
  '& .MuiInputLabel-root': {
    color: '#9e9e9e',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#9e9e9e',
    },
    '&:hover fieldset': {
      borderColor: '#c0c0c0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8a2be2',
    },
  },
});

export const CustomButton = styled(Button)({
  backgroundColor: '#8a2be2',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#6c20bb',
  },
  '&.Mui-disabled': {
    backgroundColor: '#3a3a3a',
    color: '#9e9e9e',
  },
});
