import React, { useState, useEffect } from "react";
import { useClipsActions, useClipsState, useContributorsState } from "@/store";
import { GridContainer } from "./Clip.elements";
import ClipCard from "./ClipCard";
import Pagination from "../Util/Pagination";
import SearchPanel from "../Util/SearchPanel";

const ClipsGroup = (props) => {
  const { selectedContributorId, affiliate } = useContributorsState();
  const { clips, totalPages, currentPage } =
    useClipsState();
  const { fetchClips, setPage } = useClipsActions();

  // Local state for managing the search and date range
  const [searchParams, setSearchParams] = useState({
    searchTerm: null,
    startDate: null,
    endDate: null,
  });

  const [pageSize, setPageSize] = useState(10); // Number of clips per page

  useEffect(() => {
    const {searchTerm, startDate, endDate} = searchParams;
    fetchClips({
      page: currentPage,
      pageSize,
      contributors: selectedContributorId,
      affiliate,
      headline: searchTerm,
      fromDate: startDate,
      toDate: endDate,
    });
  }, [
    fetchClips,
    currentPage,
    pageSize,
    affiliate,
    selectedContributorId,
    searchParams
  ]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setPage(pageNumber); // Update the current page in the Redux store
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize); // Update page size
    setPage(0); // Reset to the first page when page size changes
  };

  return (
    <>
      <SearchPanel setSearchParams={setSearchParams} />
      <GridContainer>
        {clips.map((file) => (
          <ClipCard key={file.fileName} file={file} />
        ))}
      </GridContainer>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </>
  );
};

export default ClipsGroup;
