import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { ClipFormContainer } from "./Form.elements";
import { SearchFormWrapper } from "../PackagesSection/SearchForm.elements";
import ClipSearchTextField from "../Clips/ClipSearchTextField";
import MuiClipDateRangePicker from "@/components/Clips/MuiClipDateRangePicker";
import { PackageViewSwitcher } from '../PackagesSection/PackageViewSwitcher';
import countries from "@/components/Util/countries.json";

const initParams = {
  country: '',
  searchTerm: '',
  startDate: undefined,
  endDate: undefined,
}

const SearchPanel = ({ setSearchParams, hasCountryField = false, hasViewSwitcher = false }) => {
  const [localSearchParams, setLocalSearchParams] = useState(initParams);

  const updateLocalSearchParams = (v) => setLocalSearchParams((s) => ({ ...s, ...v }))

  const onSearch = () => {
    setSearchParams(localSearchParams);
  }

  const onResetFilters = () => {
    setSearchParams(initParams)
    setLocalSearchParams(initParams);
  }

  return (
    <ClipFormContainer>
      <SearchFormWrapper>
        <ClipSearchTextField 
          value={localSearchParams.searchTerm}
          onChange={(e) => updateLocalSearchParams({ searchTerm: e.target.value })}
        />
        {hasCountryField && (
          <FormControl sx={{ minWidth: 'min(100%, 250px)' }}>
            <InputLabel id="country-select-label">Country</InputLabel>
            <Select
              labelId="country-select-label"
              placeholder="Select Country"
              label="Country"
              value={localSearchParams.country}
              onChange={(e) => updateLocalSearchParams({ country: e.target.value })}
            >
              <MenuItem key="empty" value="">
                  Select Country
                </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <MuiClipDateRangePicker
          label="Start Date"
          value={localSearchParams.startDate}
          onChange={(startDate) => updateLocalSearchParams({ startDate })}
          format="YYYY/MM/DD"
        />
        <MuiClipDateRangePicker
          label="End Date"
          value={localSearchParams.endDate}
          onChange={(endDate) => updateLocalSearchParams({ endDate })}
          format="YYYY/MM/DD"
        />
        <Stack direction="row" gap="8px" justifyContent="space-between">
          <Button
            onClick={onSearch}
            variant="contained"
            sx={{
              borderColor: "#40A31E",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "18px 38px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#8095B7",
              },
            }}
          >
            Search
          </Button>

          <Button
            onClick={onResetFilters}
            variant="outlined"
            sx={{
              borderColor: "#707070",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "8px",
              padding: "18px 38px",
              textTransform: "none",
              "&:hover": {
                borderColor: "#8095B7",
              },
            }}
          >
            Reset
          </Button>
        </Stack>
      </SearchFormWrapper>
      {hasViewSwitcher && <PackageViewSwitcher />}
    </ClipFormContainer>
  );
};

export default SearchPanel;
