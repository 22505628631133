import styled from "@emotion/styled";

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 5px;
`;

export const InteractiveStar = styled.span`
  font-size: 24px;
  margin: 2px;
  cursor: pointer;
  color: ${({ isFilled }) => (isFilled ? '#F5C754' : '#ccc')};
  transition: color 0.2s;

  &:hover ~ & {
    color: #FFD700;
  }
`;

export const Star = styled.span`
  font-size: 24px;
  margin: 2px;
  cursor: pointer;
  color: ${({ isFilled }) => (isFilled ? '#F5C754' : '#ccc')};
  transition: color 0.2s;
`;

export const RatingRow = styled.div`
  display: flex;
  align-items: center;
`;

export const RatingTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-right: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const FormRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
`;
