import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@/icons";
import { theme } from "@/theme/theme";

const ClipSearchTextField = ({ value, onChange }) => {

  return (
    <TextField
      id="filled-basic"
      label="Enter clip description"
      variant="filled"
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      sx={{
        "@media (max-width: 1008px)": {
          width: "100%",
        },
        '& .MuiFilledInput-root': {
          borderRadius: '8px',
        },
        '& .MuiFilledInput-underline:before': {
          borderBottom: 'none',
        },
        '& .MuiFilledInput-underline:after': {
          borderBottom: 'none',
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.primary.light2,
        },
      }}
    />
  );
};

export default ClipSearchTextField;
